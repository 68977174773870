import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModeCategory, ModeName } from '@commutifi/modes'
import { FETCH_ORGANIZATION_SUCCESS, IFetchOrganizationSuccess } from 'store/modules/enterprise/types'

interface AnalyticFiltersState {
  modes: { selectedModes: { [key in ModeCategory]?: ModeName[] } | undefined }
  offices: { selectedOffices: string[] | undefined }
  cities: { selectedCities: string[] | undefined }
  attributes: { selectedAttributes: Record<string, string[]> | undefined }
  remoteStatus: {
    includeRemote: boolean
  }
  units: { isMetric: boolean }
}

const byEnterpriseInitialState: {
  enterpriseId: string | null
  byId: Record<string, AnalyticFiltersState | undefined>
} = {
  enterpriseId: null,
  byId: {}
}

const selectedAttributesInitialState: AnalyticFiltersState['attributes'] = {
  selectedAttributes: {}
}
const selectedCitiesInitialState: AnalyticFiltersState['cities'] = {
  selectedCities: []
}
const selectedModesInitialState: AnalyticFiltersState['modes'] = {
  selectedModes: {}
}
const selectedOfficesInitialState: AnalyticFiltersState['offices'] = {
  selectedOffices: []
}
const remoteStateInitialState: AnalyticFiltersState['remoteStatus'] = {
  includeRemote: false
}
const unitsInitialState: AnalyticFiltersState['units'] = {
  isMetric: true
}
const filtersInitialState: AnalyticFiltersState = {
  modes: selectedModesInitialState,
  offices: selectedOfficesInitialState,
  cities: selectedCitiesInitialState,
  attributes: selectedAttributesInitialState,
  remoteStatus: remoteStateInitialState,
  units: unitsInitialState
}

const getCurrentEnterprise = (state: typeof byEnterpriseInitialState) => state.byId[state.enterpriseId || '']

const analyticsFiltersSlice = createSlice({
  name: 'analyticsFilters',
  initialState: byEnterpriseInitialState,
  reducers: {
    setSelectedCities: (state, action: PayloadAction<string[] | undefined>) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.cities.selectedCities = action.payload
      }
    },
    setSelectedAttributes: (state, action: PayloadAction<AnalyticFiltersState['attributes']['selectedAttributes']>) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.attributes.selectedAttributes = action.payload
      }
    },
    setSelectedModes(state, action: PayloadAction<AnalyticFiltersState['modes']['selectedModes']>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.modes.selectedModes = action.payload
      }
    },
    setSelectedOffices: (state, action: PayloadAction<string[] | undefined>) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.offices.selectedOffices = action.payload
      }
    },
    setIncludeRemote: (state, action) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.remoteStatus.includeRemote = action.payload
      }
    },
    toggleUnit: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.units.isMetric = !currentEnterprise.units.isMetric
      }
    },
    setUnitToMetric: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.units.isMetric = true
      }
    },
    setUnitToImperial: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.units.isMetric = false
      }
    },
    setAnalyticsFilters: (state, action: PayloadAction<AnalyticFiltersState>) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        Object.assign(currentEnterprise, action.payload)
      }
    },
    resetAttributes: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.attributes.selectedAttributes = selectedAttributesInitialState.selectedAttributes
      }
    },
    resetCities: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.cities.selectedCities = selectedCitiesInitialState.selectedCities
      }
    },
    resetModes: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.modes.selectedModes = selectedModesInitialState.selectedModes
      }
    },
    resetSelectedOffices: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.offices.selectedOffices = selectedOfficesInitialState.selectedOffices
      }
    },
    resetRemoteStatus: (state) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.remoteStatus = remoteStateInitialState
      }
    },
    resetAllFilters: (state) => {
      state.byId[state.enterpriseId || ''] = filtersInitialState
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<typeof byEnterpriseInitialState>) => {
    builder.addCase(FETCH_ORGANIZATION_SUCCESS, (state, action: IFetchOrganizationSuccess) => {
      const newEnterpriseId = action.response.result.enterprise || action.response.result.organization
      if (newEnterpriseId) {
        state.enterpriseId = newEnterpriseId
        if (!state.byId[newEnterpriseId]) {
          state.byId[newEnterpriseId] = filtersInitialState
        }
      }
    })
  }
})

export const analyticFiltersReducer = analyticsFiltersSlice.reducer
export const analyticFiltersActions = analyticsFiltersSlice.actions
export const analyticFiltersSelectors = analyticsFiltersSlice.selectors
