import { extractLocaleInformation, SupportedLocales } from '@commutifi/constants/Locale'
import { DEFAULT_LOCALE } from 'constants/settings'
import { AccountActionTypes } from 'store/modules/accounts/actions'
import * as accountTypes from 'store/modules/accounts/types'
import { captureError } from 'utils/helpers/errors'
import { LocalSettingsActionTypes } from './actions'
import * as types from './types'
import { LocalesType, isSupportedLocaleType, isFrontendLocaleType } from '@commutifi-fe/constants'

const initialState = () => {
  const browserLocale = extractLocaleInformation((navigator.language || DEFAULT_LOCALE) as SupportedLocales).baseName
  const locale =
    isSupportedLocaleType(browserLocale) || isFrontendLocaleType(browserLocale) ? browserLocale : DEFAULT_LOCALE
  const initState = { locale }
  return initState
}

export function settingsReducer(
  state = initialState(),
  action: LocalSettingsActionTypes | AccountActionTypes = {} as any
) {
  switch (action.type) {
    case types.CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale
      }
    case accountTypes.UPDATE_ACCOUNT_REQUEST:
      // We use the REQUEST action type here cause we still want to locally (on the Frontend) change the locale
      // even if for some reason the update ends with a failure. Only side effect is it won't be persisted if the user
      // closes the browser but that fine as an error handling.
      if (action.payload?.preferredLocale) {
        return {
          ...state,
          locale: action.payload.preferredLocale as LocalesType
        }
      }
      return state
    case accountTypes.UPDATE_ACCOUNT_FAILURE:
      if (action.error.payload.preferredLocale) {
        captureError(
          new Error(
            'Fail to persist preferred locale on Account. Locale is still saved in redux state and persisted locale storage'
          ),
          {
            extra: {
              locale: action.error.payload.preferredLocale
            }
          }
        )
      }
      return state
    case accountTypes.FETCH_ME_SUCCESS: {
      // Update redux state locale based on the account preference
      const me = action.payload.entities.account[action.payload.result]
      if (me?.preferredLocale && me.preferredLocale !== state.locale) {
        return {
          ...state,
          locale: me.preferredLocale as LocalesType
        }
      }
      return state
    }
    default:
      return state
  }
}
export type LocalSettingsState = ReturnType<typeof settingsReducer>
export default settingsReducer
