import { spacing } from '@commutifi-fe/ui/styles/constants';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlRegex =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const uuidPattern =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const alphaNumericRegex = /^[a-zA-Z0-9]+$/;

export const termsLink = 'https://www.commutifi.com/terms-of-use';
export const policyLink = 'https://www.commutifi.com/privacy-policy';
export const contactUsLink = 'https://www.commutifi.com/company/contact-us';
export const homeLink = 'https://www.commutifi.com';

export const helpEmail = 'help@commutifi.com';
export const dcCashoutGovEmail = 'parkingcashout@dc.gov';
export const dcCashoutEmail = 'dccashout@commutifi.com';

// !IMPORTANT, it needs to be the sames values as we set in our scss (@commutifi-fe/style/variables/responsiveness.scss)
export const screenSizes = {
  xsMaxWidth: '575.98px', // Extra small devices: < 576px
  smMinWidth: '576px',
  smMaxWidth: '759.98px', // Small devices (landscape phones, less than 768px): >= 576px && < 760px
  mdMinWidth: '760px',
  mdMaxWidth: '1023.98px', // Medium devices (tablets, less than 992px): >= 760px && < 992px
  lgMinWidth: '1024px',
  lgMaxWidth: '1279.98px', // Large devices (desktops, less than 1200px): >= 992px && < 1200px
  xlMinWidth: '1280px',
  xlMaxWidth: '1599.98px', // Extra large devices (large desktop): >= 1200px && < 1600px
};

export const large2DGutter: [number, number] = [
  parseInt('large' in spacing ? spacing.large : '24px'),
  parseInt('large' in spacing ? spacing.large : '24px'),
];

export const WEEKS_PER_MONTH = 4.34524;
export const ROUTE_WEEKLY_FREQUENCY = 2 * 5;

export const COMMUTIFI_TRANSACTION_FEES = 4.5;

export const LOCATION_RADIUS_IN_M = 150000;
export const STORAGE_THEME_KEY = 'theme';
