import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import storageSession from 'redux-persist/es/storage/session'
import * as authTypes from 'store/modules/auth/types'
import type { AccountActionTypes } from './modules/accounts/actions'
import account from './modules/accounts/reducer'
import type { AuthActionType } from './modules/auth/actions'
import auth, { AuthState } from './modules/auth/reducer'
import benefits from './modules/benefits/reducer'
import header from './modules/components/header'
import modals from './modules/components/modals'
import enterprise, { EnterpriseState } from './modules/enterprise/reducer'
import { OrganizationActionTypes } from './modules/enterprise/types'
// New file structure
import enterpriseSurvey from './modules/enterpriseSurvey'
import legs from './modules/legs/reducer'
import localSettings from './modules/localSettings/reducer'
import metrics from './modules/metrics/reducer'
import plans from './modules/plans/reducer'
import providers from './modules/providers/reducer'
import routes from './modules/routes/reducer'
import services from './modules/services/reducer'
import surveyBuilder from './modules/surveyBuilder/slice'
import UI from './modules/UI'

export const persistBlacklist = [
  'auth',
  'account',
  'modals',
  'form',
  'billing',
  'profile',
  'authentication',
  'enterpriseSurvey',
  'enterprise'
]

const authPersistConfig = {
  key: 'auth',
  storage
}

const surveyPersistConfig = {
  key: 'enterpriseSurvey',
  storage: storageSession
}

const enterprisePersistConfig = {
  key: 'enterprise',
  storage,
  whitelist: ['currentId']
}

const appReducer = combineReducers({
  modals,
  header,
  enterprise: persistReducer<Partial<EnterpriseState>, OrganizationActionTypes>(enterprisePersistConfig, enterprise),
  auth: persistReducer<Partial<AuthState>, AuthActionType>(authPersistConfig, auth),
  account,
  localSettings,
  metrics,
  routes,
  legs,
  services,
  providers,
  plans,
  benefits,
  enterpriseSurvey: persistReducer(surveyPersistConfig, enterpriseSurvey),
  surveyBuilder,
  UI
})

const logoutPersistedStates: Record<string, boolean> = {
  localSettings: true,
  enterpriseSurvey: true
}
const rootReducer = (state: ReturnType<typeof appReducer>, action: AccountActionTypes | AuthActionType) => {
  if (
    action.type === authTypes.LOGOUT_SUCCESS ||
    action.type === authTypes.UNAUTHORIZE_USER ||
    action.type === authTypes.CLEAR_USER
  ) {
    state = Object.keys(state).reduce(
      (acc, key) => ({ ...acc, [key]: logoutPersistedStates[key] ? state[key] : undefined }),
      state
    )
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof appReducer>
export default rootReducer
