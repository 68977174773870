import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { HttpMethod } from '../../constants'

function replaceEnterpriseId(enterpriseId: string): string {
  return this.path.replace(':enterpriseId', enterpriseId)
}
function replaceOrganizationId(organizationId: string): string {
  return this.path.replace(':organizationId', organizationId)
}
function returnPath(): string {
  return this.path
}
function replaceKindAndId(kind: EnterpriseKind, entityId: string): string {
  return this.path
    .replace(':kind', kind === EnterpriseKind.organization ? 'organizations' : 'enterprises')
    .replace(':entityId', entityId)
}

export const organizationEndpoints = {
  [HttpMethod.Get]: {
    OrganizationLegacyGroups: {
      path: '/_/organizations/:enterpriseId/groups',
      route: replaceEnterpriseId
    },
    EnterpriseDashboard: {
      path: '/enterprises/:enterpriseId/dashboard',
      route: replaceEnterpriseId
    },
    EnterpriseFeatureFlags: {
      path: '/enterprises/:enterpriseId/feature-flags',
      route: replaceEnterpriseId
    },
    EnterpriseServiceBookingsCount: {
      path: '/enterprises/:enterpriseId/services/:serviceId/bookings/count',
      route(enterpriseId: string, serviceId: string): string {
        return this.path.replace(':enterpriseId', enterpriseId).replace(':serviceId', serviceId)
      }
    },
    EnterprisePlan: {
      path: '/enterprises/:enterpriseId/plans/:planId',
      route(enterpriseId: string, planId: string): string {
        return this.path.replace(':enterpriseId', enterpriseId).replace(':planId', planId)
      }
    },
    EnterpriseMerchants: {
      path: '/enterprises/:enterpriseId/merchants',
      route: replaceEnterpriseId
    },
    EnterpriseBookings: {
      path: '/enterprises/:enterpriseId/bookings',
      route: replaceEnterpriseId
    },
    EnterpriseBookingsFilters: {
      path: '/enterprises/:enterpriseId/plans/filters',
      route: replaceEnterpriseId
    },
    EnterpriseAccounts: {
      path: '/enterprises/:enterpriseId/accounts',
      route: replaceEnterpriseId
    },
    EnterpriseCities: {
      path: '/enterprises/:enterpriseId/accounts/cities',
      route: replaceEnterpriseId
    },
    EnterpriseOffices: {
      path: '/enterprises/:enterpriseId/offices',
      route: replaceEnterpriseId
    },
    EnterprisePaymentMethods: {
      path: '/enterprises/:enterpriseId/payment-methods',
      route: replaceEnterpriseId
    },
    EnterpriseInvoices: {
      path: '/enterprises/:enterpriseId/invoices',
      route: replaceEnterpriseId
    },
    EnterpriseDeposits: {
      path: '/enterprises/:enterpriseId/enterprise-deposits',
      route: replaceEnterpriseId
    },
    EnterpriseWallets: {
      path: '/enterprises/:enterpriseId/wallets',
      route: replaceEnterpriseId
    },
    EnterpriseWalletEnrolments: {
      path: '/enterprises/:enterpriseId/accounts-wallets',
      route: replaceEnterpriseId
    },
    EnterpriseGroupsWallets: {
      path: '/enterprises/:enterpriseId/groups-wallets',
      route: replaceEnterpriseId
    },
    EnterpriseChildren: {
      path: '/enterprises/:enterpriseId/sub-enterprises',
      route: replaceEnterpriseId
    },
    EnterprisePlans: {
      path: '/enterprises/:enterpriseId/plans',
      route: replaceEnterpriseId
    },
    EnterprisePlanServiceLatestInventory: {
      path: '/enterprises/:enterpriseId/plans/:planId/services/inventory',
      route(enterpriseId: string, planId: string): string {
        return this.path.replace(':enterpriseId', enterpriseId).replace(':planId', planId)
      }
    },
    EnterpriseNotificationTemplates: {
      path: '/enterprises/:enterpriseId/templates/notifications',
      route: replaceEnterpriseId
    },
    EnterpriseCommuterDatasets: {
      path: '/enterprises/:enterpriseId/commuter-datasets',
      route: replaceEnterpriseId
    },
    AdminSubmissions: {
      path: '/organizations/:organizationId/ddot-submissions',
      route: replaceOrganizationId
    },
    AdminSubmission: {
      path: '/organizations/:organizationId/ddot-submissions/:submissionId',
      route(organizationId: string, submissionId: string): string {
        return this.path.replace(':organizationId', organizationId).replace(':submissionId', submissionId)
      }
    },
    EnterpriseAttributes: {
      path: '/enterprises/:enterpriseId/attributes',
      route: replaceEnterpriseId
    },
    EnterpriseBalances: {
      path: '/enterprises/:enterpriseId/balances',
      route: replaceEnterpriseId
    },
    SuggestEnterprises: {
      path: '/suggest/enterprises',
      route: returnPath
    },
    SubEnterprises: {
      path: '/organizations/:enterpriseId/enterprises',
      route: replaceEnterpriseId
    },
    VerifyHasManager: {
      path: '/:kind/:entityId/hasManager',
      route: replaceKindAndId
    },
    OrganizationMetrics: {
      path: '/organizations/:organizationId/metrics',
      route: replaceOrganizationId
    },
    OrganizationManagers: {
      path: '/organizations/:organizationId/managers',
      route: replaceOrganizationId
    },
    Offices: {
      path: '/:kind/:entityId/offices',
      route: replaceKindAndId
    },
    FundingInstructions: {
      path: '/enterprises/:enterpriseId/funding-instructions',
      route: replaceEnterpriseId
    },
    ShippingRates: {
      path: '/enterprises/:enterpriseId/shipping-rates',
      route: replaceEnterpriseId
    }
  },
  [HttpMethod.Patch]: {
    Enterprise: {
      path: '/enterprises/:enterpriseId',
      route: replaceEnterpriseId
    },
    OrganizationAccountInvite: {
      path: '/organizations/:enterpriseId/accounts/invite',
      route: replaceEnterpriseId
    },
    SetEnterprisePaymentMethodDefault: {
      path: '/enterprises/:enterpriseId/payment-methods/:paymentMethodId/default',
      route(enterpriseId: string, paymentMethodId: string): string {
        return this.path.replace(':enterpriseId', enterpriseId).replace(':paymentMethodId', paymentMethodId)
      }
    },
    SubmissionAdmin: {
      path: '/organizations/:organizationId/ddot-submissions/:submissionId',
      route(organizationId: string, submissionId: string): string {
        return this.path.replace(':organizationId', organizationId).replace(':submissionId', submissionId)
      }
    }
  },
  [HttpMethod.Post]: {
    EnterprisePlanPermissions: {
      path: '/enterprises/:enterpriseId/plans/plan-permissions',
      route: replaceEnterpriseId
    },
    Enterprises: {
      path: '/enterprises',
      route: returnPath
    },
    Managers: {
      path: '/accounts/managers',
      route: returnPath
    },
    EnterpriseAccounts: {
      path: '/:kind/:entityId/accounts',
      route: replaceKindAndId
    },
    ImportAccounts: {
      path: '/:kind/:entityId/employees/import',
      route: replaceKindAndId
    },
    ImportBookings: {
      path: '/organizations/:organizationId/bookings/import',
      route: replaceOrganizationId
    },
    SubEnterprises: {
      path: '/organizations/:enterpriseId/sub-enterprises',
      route: replaceEnterpriseId
    },
    SubEnterpriseManager: {
      path: '/organizations/:enterpriseId/managers',
      route: replaceEnterpriseId
    },
    PaymentMethods: {
      path: '/enterprises/:enterpriseId/payment-methods',
      route: replaceEnterpriseId
    }
  },
  [HttpMethod.Put]: {
    EnterpriseNotificationTemplates: {
      path: '/enterprises/:enterpriseId/templates/notifications',
      route: replaceEnterpriseId
    },
    UpsertOffice: {
      path: '/enterprises/:enterpriseId/offices',
      route: replaceEnterpriseId
    },
    ImportOffices: {
      path: '/enterprises/:enterpriseId/offices/import',
      route: replaceEnterpriseId
    }
  },
  [HttpMethod.Delete]: {
    Manager: {
      path: '/enterprises/:enterpriseId/manager/:managerId',
      route(enterpriseId: string, managerId: string): string {
        return this.path.replace(':enterpriseId', enterpriseId).replace(':managerId', managerId)
      }
    }
  }
}

export default organizationEndpoints
