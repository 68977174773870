import { isNil, merge, omit } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { QuestionProps } from '@commutifi/models/Question'
import { SurveyProps } from '@commutifi/models/Surveys'
import { PatchQuestionBody } from 'api/modules/survey/types'
import { getArray } from 'utils/helpers'

export interface SurveyBuilderState {
  baseSurvey: SurveyProps | null
  questionsById: Record<string, QuestionProps>
}

const initialState: SurveyBuilderState = {
  baseSurvey: null,
  questionsById: {}
}

const surveyBuilderSlice = createSlice({
  name: 'surveyBuilder',
  initialState,
  reducers: {
    /**
     * Set the base survey - This object is what will be used to create the survey object and keep track of the questions
     * and updates done on the survey that is being created or modified on the UI.
     * @param state - The current state
     * @param action - The action containing the survey to set
     * @returns The updated state
     */
    setBaseSurvey: (state, action: PayloadAction<SurveyProps>) => {
      state.baseSurvey = action.payload
      state.questionsById =
        action.payload.questionsSurveys?.reduce<Record<string, QuestionProps>>((acc, qs) => {
          if (!qs.question || !qs.question.id) return acc
          acc[qs.question.id] = qs.question
          return acc
        }, {}) ?? {}
    },
    /**
     * Update the base survey - This will update the base survey with the new survey object and merge the surveysContents.
     * The questions should be left untouched as they are managed independently.
     * @param state - The current state
     * @param action - The action containing the survey to update
     * @returns The updated state
     */
    updateSurvey: (state, action: PayloadAction<SurveyProps>) => {
      if (!state.baseSurvey) return
      state.baseSurvey = {
        ...state.baseSurvey,
        ...action.payload,
        surveysContents: merge(state.baseSurvey.surveysContents, action.payload.surveysContents),
        questionsSurveys: merge(state.baseSurvey.questionsSurveys, action.payload.questionsSurveys)
      }
    },
    /**
     * Update one or many questions in the base survey. If the question is not found in the base survey, it will be ignored.
     * This is to ensure the questions order stays valid.
     * We support both original question object and patched question object. The patched question object is the one returned by the API
     * and it will properly be formatted back to a valid original question object.
     * @param state - The current state
     * @param action - The action containing the questions to update
     * @returns The updated state
     */
    updateQuestions: (
      state,
      action: PayloadAction<QuestionProps | QuestionProps[] | PatchQuestionBody | PatchQuestionBody[]>
    ) => {
      if (!state.baseSurvey) return
      const questions = getArray(action.payload)

      // Support either patched question or original question object -> Format back the single questionSurvey link object
      // to an array like in  the original question object
      const isPatchQuestionType = (q: QuestionProps | PatchQuestionBody): q is PatchQuestionBody =>
        typeof q === 'object' && 'questionSurvey' in q
      const formattedQuestions = questions.map((q) => {
        if (isPatchQuestionType(q)) {
          return {
            ...q,
            questionsSurveys: [q.questionSurvey]
          } as QuestionProps
        }
        return q
      })
      const newQuestionsSurveys = formattedQuestions.flatMap<QuestionProps>(
        (q) =>
          q.questionsSurveys?.filter(Boolean).map((qs) => ({
            ...qs,
            question: omit(q, ['questionsSurveys'])
          })) || []
      )

      const questionIndexByQuestionId = state.baseSurvey?.questionsSurveys
        ?.map((qs) => qs.question)
        .reduce<Record<string, number>>((acc, q, index) => {
          if (!q || !q.id) return acc
          acc[q.id] = index
          return acc
        }, {})

      newQuestionsSurveys.forEach((qs) => {
        if (!qs.id || isNil(questionIndexByQuestionId?.[qs.id]) || !state.baseSurvey?.questionsSurveys) {
          // We only allow setting questions that already exists so if we can't find it
          // we don't add it. This ensures the questions order stays valid
          return
        }
        state.baseSurvey.questionsSurveys[questionIndexByQuestionId[qs.id]] = qs
      })
    },

    /**
     * Move a section from one position to another, updating all affected sections' numbers
     * @param state - The current state
     * @param action - The action containing the from and to positions
     * @returns The updated state
     */
    moveSection: (state, action: PayloadAction<{ from: number; to: number }>) => {
      if (!state.baseSurvey || !state.baseSurvey.questionsSurveys) return

      const { from, to } = action.payload

      state.baseSurvey.questionsSurveys.forEach((qs) => {
        if (!qs.section) return

        if (from < to) {
          // Moving section down
          // Decrease section numbers for questions between 'from' and 'to'
          if (qs.section > from && qs.section <= to) {
            qs.section -= 1
          } else if (qs.section === from) {
            // Set the moved section to its new position
            qs.section = to
          }
        } else if (from > to) {
          // Moving section up
          // Increase section numbers for questions between 'to' and 'from'
          if (qs.section >= to && qs.section < from) {
            qs.section += 1
          } else if (qs.section === from) {
            // Set the moved section to its new position
            qs.section = to
          }
        }
      })
    }
  }
})

// Action creators are generated for each case reducer function
export const surveyBuilderActions = surveyBuilderSlice.actions

export default surveyBuilderSlice.reducer
