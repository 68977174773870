import React from 'react'
import { createRoot } from 'react-dom/client'
import { persistStore } from 'redux-persist'
import { STAY_LOGGED_IN_STORAGE_ID } from 'constants/settings'
import store from './store'
import App from './app'
import httpService from './api/interceptors'
// Add our custom commutifi icon fonts and text fonts
import '@commutifi-fe/fonts/fonts.css'
import './styles/main.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

async function enableMocking() {
  if (process.env.ENABLE_API_MOCK !== 'true') {
    return
  }

  const { worker } = await import('api/__mocks__/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass'
  })
}

const mountNode = document.getElementById('root')
const root = createRoot(mountNode || document.createElement('div'))

// ========================================================
// Store
// ========================================================
const persistor = persistStore(store)
httpService.setupInterceptors(store)

// ========================================================
// Render
// ========================================================

// ========================================================
// Accessibility analysis
// Track React 18 support: https://github.com/dequelabs/axe-core-npm/issues/500
// ========================================================
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require -- All good
  const axe = require('@axe-core/react')
  axe(React, root, 1000)
}

/**
 * Transfers sessionStorage from one tab to another. Those events are possible only from the same domain!
 * Source for solution: https://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/
 * Source for event handling: https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs
 */
const sessionStorageTransfer = (event: StorageEvent) => {
  if (!event) {
    if (window.event) {
      event = window.event as StorageEvent
    }
    return
  } // ie suq
  if (!event.newValue || !event.isTrusted) return
  if (event.key === 'getSessionStorage') {
    // another tab asked for the sessionStorage -> send it
    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem('sessionStorage') // <- could do short timeout as well.
  } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
    // another tab sent data <- get it
    const data = JSON.parse(event.newValue)
    Object.keys(data).forEach((key) => {
      sessionStorage.setItem(key, data[key])
    })
  }
}

// listen for changes to localStorage
if (window.addEventListener) {
  window.addEventListener('storage', sessionStorageTransfer, false)
}

// Ask other tabs for session storage (this is ONLY to trigger event). This use case is only
// to allow opening new tabs and not logging out the user when the option to stay logged in is not selected
if (
  !Object.keys(sessionStorage).filter((key) => !key.startsWith('persist:')).length &&
  localStorage.getItem(STAY_LOGGED_IN_STORAGE_ID) === 'false'
) {
  localStorage.setItem('getSessionStorage', 'please')
  localStorage.removeItem('getSessionStorage')
}

enableMocking()
  .then(() => {
    root.render(<App store={store} persistor={persistor} />)
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Failed to enable mocking', error)
  })
