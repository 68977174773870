import { HttpMethod } from '../../constants'

function replaceAccountId(accountId: string): string {
  return this.path.replace(':accountId', accountId)
}
function replaceEnterpriseId(enterpriseId: string): string {
  return this.path.replace(':enterpriseId', enterpriseId)
}
function replaceSurveyId(surveyId: string): string {
  return this.path.replace(':surveyId', surveyId)
}
function replaceSurveyIdQuestionId(surveyId: string, questionId: string): string {
  return this.path.replace(':surveyId', surveyId).replace(':questionId', questionId)
}
function replaceSurveyIdAndSection(surveyId: string, section: string | number): string {
  return this.path.replace(':surveyId', surveyId).replace(':section', section)
}
function returnPath() {
  return this.path
}

export const surveyEndpoints = {
  [HttpMethod.Get]: {
    SurveysLinks: {
      path: '/enterprises-surveys',
      route: returnPath
    },
    SurveyShortlink: {
      path: '/enterprises-surveys/shortlink-id',
      route: returnPath
    },
    DefaultSurvey: {
      path: '/enterprises-surveys/default-survey',
      route: returnPath
    },
    Surveys: {
      path: '/surveys',
      route: returnPath
    },
    OneSurvey: {
      path: '/surveys/:surveyId',
      route: replaceSurveyId
    },
    EnterpriseSurveys: {
      path: '/enterprises/:enterpriseId/surveys',
      route: replaceEnterpriseId
    }
  },
  [HttpMethod.Post]: {
    SurveyAnswers: {
      path: '/surveys/:surveyId/answers',
      route: replaceSurveyId
    },
    OnboardSurveyWithAccount: {
      path: '/accounts/:accountId/onboarding',
      route: replaceAccountId
    },
    FollowUpSurvey: {
      path: '/accounts/:accountId/answers',
      route: replaceAccountId
    },
    EnterprisesSurveys: {
      path: '/enterprises-surveys',
      route: returnPath
    },
    CreateSurvey: {
      path: '/surveys',
      route: returnPath
    },
    CreateManyQuestions: {
      path: '/surveys/:surveyId/questions',
      route: replaceSurveyId
    },
    DuplicateSurveyPage: {
      path: '/surveys/:surveyId/questions/section/:section/duplicate',
      route: replaceSurveyIdAndSection
    }
  },
  [HttpMethod.Patch]: {
    AnonymousSurveyAccount: {
      path: '/surveys/:surveyId/accounts',
      route: replaceSurveyId
    },
    PatchSurvey: {
      path: '/surveys/:surveyId',
      route: replaceSurveyId
    },
    PatchOneQuestion: {
      path: '/surveys/:surveyId/questions/:questionId',
      route: replaceSurveyIdQuestionId
    },
    MoveSurveyPage: {
      path: '/surveys/:surveyId/questions/section/:section',
      route: replaceSurveyIdAndSection
    }
  },
  [HttpMethod.Delete]: {
    DeleteOneQuestion: {
      path: '/surveys/:surveyId/questions/:questionId',
      route: replaceSurveyIdQuestionId
    },
    SurveyLink: {
      path: '/enterprises-surveys/:enterpriseSurveyId',
      route: (enterpriseSurveyId: string) => `/enterprises-surveys/${enterpriseSurveyId}`
    },
    SurveyPage: {
      path: '/surveys/:surveyId/questions/section/:section',
      route: replaceSurveyIdAndSection
    }
  }
}

export default surveyEndpoints
