import { EnterpriseSurveyProps } from '@commutifi/models/EnterprisesSurveys'
import { SurveyContentProps, SurveyProps } from '@commutifi/models/Surveys'
import { QuestionSurveyProps } from '@commutifi/models/QuestionSurvey'
import { QuestionProps, QuestionType } from '@commutifi/models/Question'
import { ModeCategory, ModeName } from '@commutifi/modes'
import { Overwrite, Required } from 'utility-types'
import { OfficeWithBuilding } from './Office'
import { Account } from './Account'
import { QuestionContentProps } from '@commutifi/models/QuestionContent'

type PartialBuilding = Omit<
  OfficeWithBuilding['building'],
  'streetNumber' | 'street' | 'city' | 'postalCode' | 'state' | 'country'
>

export const QUESTION_TYPES = {
  ...QuestionType,
  commuteRouter: 'Commute Router' as const,
  secondaryCommuteRouter: 'Commute Router - Secondary' as const,
  commuteTimeline: 'Commute Timeline' as const,
  commuteTimelineDriveAndTransit: 'Commute Timeline - Drive & Transit' as const,
  activityMapper: 'Activity Selector - Mapper' as const
}

type QuestionContentOptions = {
  options?: { label: string; value: string }[]
} & Record<string, unknown>

export interface IQuestionContent {
  id: string
  locale: string
  title?: JSX.Element | string
  hint?: string | null
  placeholder?: string | null
  options?: QuestionContentOptions | null | null
  questionId: string
}

export interface Question
  extends Required<
    Overwrite<
      QuestionSurveyProps,
      { configuration?: QuestionConfiguration | null }
    >,
    'section' | 'order'
  > {
  id: string
  type: (typeof QUESTION_TYPES)[keyof typeof QUESTION_TYPES]
  referenceKey: string | string[] | null
  category: string | null
  conditionalMode: ModeName[] | null
  conditionalCategory: ModeCategory[] | null
  conditionalValue: string[] | null
  conditionalQuestionId: string | null
  content: IQuestionContent | null
}

export type FlattenQuestionSurveyProps = Omit<
  Overwrite<
    QuestionSurveyProps,
    {
      configuration?: QuestionConfiguration
    }
  >,
  'question'
> &
  Overwrite<
    QuestionProps,
    {
      conditionalMode?: ModeName[] | undefined
      conditionalCategory?: ModeCategory[] | undefined
      questionsContents?:
        | Overwrite<
            QuestionContentProps,
            { options?: QuestionContentOptions }
          >[]
        | undefined
    }
  > & {
    questionSurveyId?: string
  }

export type QuestionWithOrder = QuestionProps &
  Pick<QuestionSurveyProps, 'section' | 'order'>

/**
 * No a strict schema of the question configuration. This is just to indicate
 * the possible different values you can find in the configuration to limit
 * the errors.
 */
export interface QuestionConfiguration {
  minValue?: number
  maxValue?: number
  randomizeOrder?: boolean
  allowFrequency?: boolean
  allowCreation?: boolean
}

export interface EnterpriseSurveyResponse extends EnterpriseSurveyProps {
  shortlinkId?: string
  enterprise?: { id: string; name: string; surveyId: string } | null
  organization?: { id: string; name: string; surveyId: string } | null
  building?:
    | (PartialBuilding & {
        office: { id: string }
      })
    | null
  account?: { id: string; name: string; email?: string } | null
  survey: Omit<SurveyProps, 'surveyContents' | 'questionsSurveys'> & {
    content: SurveyContentProps & { locale: string; surveyId: string }
    questions: Question[]
  }
}

export interface PrefilledData
  extends Omit<EnterpriseSurveyResponse, 'survey'> {
  accountContext?: Overwrite<Account, { office?: OfficeWithBuilding }>
  isAddressUpdate?: boolean
}
