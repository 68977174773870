import { getCurrencyFromLocale } from '@commutifi/constants/Locale'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementLocale, StripeElementsOptions } from '@stripe/stripe-js'
import { ConfigProvider as PhoneConfigProvider } from 'antd-country-phone-input'
import 'antd-country-phone-input/dist/index.css'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import en from 'world_countries_lists/data/countries/en/world.json'
import config from 'config/index'
import fontStyles from 'constants/fonts'
import { colors, screenSizes, spacing, themeVariables } from 'constants/global'
import { CurrencyCode, Locales } from 'constants/settings'
import { localSettingsActions } from 'store/modules/localSettings'
import { theme } from '@commutifi-fe/ui'
import 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/src/cookieconsent'
import 'vanilla-cookieconsent/src/cookieconsent.css'
import { AVAILABLE_LANGUAGES, LocalesType } from '@commutifi-fe/constants'
import { Themes } from '@commutifi-fe/ui/components/theme/constants'
import { useThemeDetector } from '@commutifi-fe/hooks'

type ContinentType = 'Europe' | 'America' | 'Asia' | 'Africa'
interface LocalConfigProviderValue {
  locale: LocalesType
  isLoadingTranslations: boolean
  currency: CurrencyCode
  localeOptions: typeof AVAILABLE_LANGUAGES
  changeLocale: (locale: LocalesType | string) => localSettingsActions.ChangeLocaleAction
  isMenuAvailable: boolean
  toggleShowMenu: () => void
  showMenu: () => void
  hideMenu: () => void
  region: ContinentType
  cookieconsent: CookieConsent | null
  stripeOptions: StripeElementsOptions
  theme: { currentTheme: `${Themes}`; isSystem: boolean }
  changeTheme: (theme: `${AppThemes}`) => void
}

interface LocaleConfigProviderProps {
  locale: LocalesType
  children: React.ReactNode
  isLoadingTranslations: boolean
  changeTheme: (theme: `${AppThemes}`) => void
  currentTheme: `${AppThemes}` | undefined
}

const { useToken } = theme

const stripeLocaleMapping = new Map<LocalesType, StripeElementLocale>([
  [Locales.cs, 'cs'],
  [Locales.deDE, 'de'],
  [Locales.enAU, 'en-AU'],
  [Locales.enCA, 'en-CA'],
  [Locales.enGB, 'en-GB'],
  [Locales.enPH, 'en'],
  [Locales.enTH, 'en'],
  [Locales.enUS, 'en'],
  [Locales.enVI, 'en'],
  [Locales.esES, 'es-ES'],
  [Locales.esCL, 'es-ES'],
  [Locales.esCO, 'es-ES'],
  [Locales.esMX, 'es-ES'],
  [Locales.esUS, 'es-ES'],
  [Locales.frBE, 'fr'],
  [Locales.frFR, 'fr'],
  [Locales.frCA, 'fr-CA'],
  [Locales.itIT, 'it-IT'],
  [Locales.nlBE, 'nl'],
  [Locales.nlNL, 'nl'],
  [Locales.pl, 'pl'],
  [Locales.ptPT, 'pt']
])

const antdPhoneInputLocaleMapping = new Map<LocalesType, () => Promise<any>>([
  [Locales.cs, () => import('world_countries_lists/data/countries/cs/world.json')],
  [Locales.deDE, () => import('world_countries_lists/data/countries/de/world.json')],
  [
    Locales.enAU,
    () =>
      new Promise((resolve) => {
        resolve(en)
      })
  ],
  [
    Locales.enCA,
    () =>
      new Promise((resolve) => {
        resolve(en)
      })
  ],
  [
    Locales.enGB,
    () =>
      new Promise((resolve) => {
        resolve(en)
      })
  ],
  [
    Locales.enPH,
    () =>
      new Promise((resolve) => {
        resolve(en)
      })
  ],
  [
    Locales.enTH,
    () =>
      new Promise((resolve) => {
        resolve(en)
      })
  ],
  [
    Locales.enUS,
    () =>
      new Promise((resolve) => {
        resolve(en)
      })
  ],
  [
    Locales.enVI,
    () =>
      new Promise((resolve) => {
        resolve(en)
      })
  ],
  [Locales.esES, () => import('world_countries_lists/data/countries/es/world.json')],
  [Locales.esCL, () => import('world_countries_lists/data/countries/es/world.json')],
  [Locales.esCO, () => import('world_countries_lists/data/countries/es/world.json')],
  [Locales.esMX, () => import('world_countries_lists/data/countries/es/world.json')],
  [Locales.esUS, () => import('world_countries_lists/data/countries/es/world.json')],
  [Locales.frBE, () => import('world_countries_lists/data/countries/fr/world.json')],
  [Locales.frCA, () => import('world_countries_lists/data/countries/fr/world.json')],
  [Locales.itIT, () => import('world_countries_lists/data/countries/it/world.json')],
  [Locales.nlBE, () => import('world_countries_lists/data/countries/nl/world.json')],
  [Locales.nlNL, () => import('world_countries_lists/data/countries/nl/world.json')],
  [Locales.pl, () => import('world_countries_lists/data/countries/pl/world.json')],
  [Locales.ptPT, () => import('world_countries_lists/data/countries/pt/world.json')]
])

export const ConfigContext = React.createContext<LocalConfigProviderValue | null>(null)
export const stripePromise = loadStripe(config.stripePk, {
  betas: ['us_bank_account_beta_2'],
  apiVersion: '2020-08-27; us_bank_account_beta=v2'
})

export enum AppThemes {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system'
}

export function LocalConfigProvider({
  children,
  locale,
  isLoadingTranslations,
  changeTheme,
  currentTheme
}: LocaleConfigProviderProps) {
  const dispatch = useDispatch()
  const changeLocale = React.useCallback((l: LocalesType) => dispatch(localSettingsActions.changeLocale(l)), [dispatch])

  const [phoneIntl, setPhoneIntl] = React.useState(en)
  React.useEffect(() => {
    const lazyPhoneIntl = antdPhoneInputLocaleMapping.get(locale)
    if (lazyPhoneIntl && !locale.includes('en')) {
      lazyPhoneIntl()
        .then((module) => {
          setPhoneIntl(module.default)
        })
        .catch(() => {
          // Nothing for now
        })
    } else {
      setPhoneIntl(en)
    }
  }, [locale])

  const isMdDown = useMediaQuery({ maxWidth: screenSizes.mdMaxWidth })
  const [isMenuAvailable, setIsMenuAvailable] = React.useState(isMdDown)
  const toggleShowMenu = React.useCallback(() => {
    setIsMenuAvailable((previous) => !previous)
  }, [])
  const showMenu = React.useCallback(() => {
    setIsMenuAvailable(true)
  }, [])
  const hideMenu = React.useCallback(() => {
    setIsMenuAvailable(false)
  }, [])

  const region = React.useMemo(() => {
    const intlDateTimeFormat = Intl.DateTimeFormat
    return intlDateTimeFormat().resolvedOptions().timeZone.split('/')[0] as ContinentType
  }, [])

  const [cc, setCc] = React.useState<CookieConsent | null>(null)
  React.useEffect(() => {
    // This is our way to know if cookie consent is available in the current region. If the object is null it means it's not required
    // and we don't need to show/run the cookie consent component and we don't need to auto accept cookies when the user login (See app.ts useEffect)
    setCc(region === 'Europe' ? window.initCookieConsent() : null)
  }, [region])

  const { token } = useToken()

  const stripeOptions: StripeElementsOptions = React.useMemo(
    () => ({
      fonts: fontStyles,
      locale: stripeLocaleMapping.get(locale) || 'en',
      appearance: {
        variables: {
          colorPrimary: token.primary400,
          borderRadius: themeVariables.borderRadiusSmall,
          colorPrimaryText: token.neutral600,
          colorText: token.neutral600,
          colorTextPlaceholder: token.neutral500,
          colorTextSecondary: token.neutral500,
          colorDanger: token.feedbackDestructive500,
          colorDangerText: token.feedbackDestructive500,
          spacingGridColumn: spacing.medium2,
          // Same than antd form items margin bottom
          spacingGridRow: spacing.large,
          colorWarning: '#ffc000',
          colorWarningText: '#ffc000',
          focusOutline: token.primary300,
          focusBoxShadow: `0px 0px 2px -1px ${colors.boxShadowColor}, 0px 1px 4px -2px ${colors.boxShadowColor}`,
          fontFamily: ' Segma, sans-serif',
          fontSizeBase: '14px',
          fontLineHeight: '1.57'
        },
        rules: {
          '.Label': {
            fontWeight: themeVariables.fontWeightMedium,
            fontSize: 'var(--fontSizeBase)',
            marginBottom: spacing.small,
            color: token.neutral600
          },
          '.Input': {
            padding: `11px ${spacing.small3}`,
            color: token.neutral600,
            backgroundColor: token.colorBgContainer
          },
          '.Input:hover, .Input--invalid': {
            boxShadow: `0px 0px 2px -1px ${colors.boxShadowColor}, 0px 1px 4px -2px ${colors.boxShadowColor}`
          },
          '.Input:hover': {
            boxShadow: `0px 0px 2px -1px ${colors.boxShadowColor}, 0px 1px 4px -2px ${colors.boxShadowColor}`,
            borderColor: colors.disabled
          },
          '.Input:hover:focus, .Input:focus': {
            boxShadow: `0px 0px 2px -1px ${colors.boxShadowColor}, 0px 1px 4px -2px ${colors.boxShadowColor}`,
            borderColor: token.primary300
          },
          '.Error': {
            fontSize: 'var(--fontSizeBase)'
          },
          '.Tab': {
            backgroundColor: token.colorBgContainer
          },
          '.PickerItem': {
            backgroundColor: token.colorBgContainer
          },
          '.PickerItem:hover': {
            backgroundColor: token.colorBgElevated,
            color: 'white'
          }
        }
      }
    }),
    [
      locale,
      token.colorBgContainer,
      token.colorBgElevated,
      token.feedbackDestructive500,
      token.neutral500,
      token.neutral600,
      token.primary300,
      token.primary400
    ]
  )

  const detectedTheme = useThemeDetector()
  const value = React.useMemo(
    () => ({
      locale,
      isLoadingTranslations,
      currency: getCurrencyFromLocale(locale),
      localeOptions: AVAILABLE_LANGUAGES,
      changeLocale,
      isMenuAvailable,
      toggleShowMenu,
      showMenu,
      hideMenu,
      region,
      cookieconsent: cc,
      stripeOptions,
      changeTheme,
      theme: {
        currentTheme:
          currentTheme === AppThemes.SYSTEM
            ? detectedTheme === 'dark'
              ? Themes.DARK
              : Themes.LIGHT
            : currentTheme || Themes.LIGHT,
        isSystem: currentTheme === AppThemes.SYSTEM
      }
    }),
    [
      locale,
      isLoadingTranslations,
      changeLocale,
      isMenuAvailable,
      toggleShowMenu,
      showMenu,
      hideMenu,
      region,
      cc,
      stripeOptions,
      changeTheme,
      currentTheme,
      detectedTheme
    ]
  )

  return (
    <ConfigContext.Provider value={value}>
      <PhoneConfigProvider locale={phoneIntl}>
        {/* as StripeElementLocale works for now cause we only supports en-us and fr-ca but be careful to update
        this if you have problems with stripe showing wrong language */}
        <Elements stripe={stripePromise} options={stripeOptions}>
          {children}
        </Elements>
      </PhoneConfigProvider>
    </ConfigContext.Provider>
  )
}

export const useConfig = () => {
  const context = React.useContext(ConfigContext)
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}

export default LocalConfigProvider
