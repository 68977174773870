import difference from 'lodash/difference'
import findKey from 'lodash/findKey'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isObject from 'lodash/isObject'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { sortingOrdersEnum } from 'constants/global'
import { REF_KEYS_LIST } from 'constants/onboarding'
import { ascendingCompare, sortByKey } from 'utils/helpers'
import { getConditionals } from './conditionals'
import types from './types'
import { filterInvalidQuestions } from './_utils'
import { QuestionType } from '@commutifi/models/Question'

/**
 * ---------------- REDUCER ----------------
 */
const initialState = {
  questions: [],
  status: {
    error: null,
    loaded: false,
    isLoading: false
  }
}

/**
 * Reducer state overview:
 *
 * enterpriseSurvey: {
 *  survey: {
 *    ..survey object from the db (referenceName, backgroundImage, directOnboarding, etc.)
 *    content: { ...translations content },
 *    questions: [...only ids],
 *    status: {
 *      error: used when we catch an error in a saga to propagate the error to the UI
 *      loaded: used by the UI to know the data is loaded
 *      isLoading: used by the UI to know if we are in a loading state
 *    }
 *  },
 *  questionsById: {
 *    [questionId]: {
 *      ...question like our table entity (order, section, conditionalMode, content: { ...translated content }, etc.)
 *    }
 *    ...All the other questions by id
 *  },
 *  prefilledData (AKA shortlink): {
 *    id: shortlink id
 *    building: if the survey is linked to a building
 *    account: if the survey is linked to a account
 *    enterprise: if the survey is linked to a enterprise
 *    organization: if the survey is linked to a organization
 *  },
 *  answersByStep: {
 *    [stepNumber] : {
 *      [reference_key || question.id]: { ...answer (could be any type) }
 *    },
 *    ... Other step answers
 *  }
 * }
 *
 */
const survey = (state = initialState, action = {}) => {
  const previousQuestions = state.questions || []
  switch (action.type) {
    case types.FETCH_SURVEY_REQUEST:
    case types.FETCH_DEFAULT_SURVEY_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          loaded: false,
          error: null,
          isLoading: true
        }
      }
    case types.FETCH_SURVEY_SUCCESS:
    case types.FETCH_DEFAULT_SURVEY_SUCCESS:
      return {
        ...action.response.result.survey,
        status: {
          ...state.status,
          error: null,
          loaded: true,
          isLoading: false
        }
      }
    case types.FETCH_SURVEY_FAILURE:
    case types.FETCH_DEFAULT_SURVEY_FAILURE:
      return {
        ...initialState,
        status: {
          error: action.error,
          loaded: false,
          isLoading: false
        }
      }
    case types.UPSERT_SURVEY_QUESTION:
      return {
        ...state,
        questions: uniq([...previousQuestions, ...action.payload.questions.map((q) => q.id)])
      }
    case types.REMOVE_SURVEY_QUESTION:
      return {
        ...state,
        questions: previousQuestions.filter((questionId) => !action.payload.questionIds.includes(questionId))
      }
    case types.RESET_SURVEY:
      return {
        questions: [],
        status: {
          error: null,
          loaded: false,
          isLoading: false
        }
      }
    default:
      return state
  }
}

const questionsById = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_SURVEY_SUCCESS:
    case types.FETCH_DEFAULT_SURVEY_SUCCESS:
      return action.response.entities.questions || {}
    case types.UPSERT_SURVEY_QUESTION:
      return {
        ...state,
        ...action.payload.questions.reduce(
          (byId, question) => ({
            ...byId,
            [question.id]: { ...state[question.id], ...question }
          }),
          {}
        )
      }
    case types.REMOVE_SURVEY_QUESTION:
      return omit(state, action.payload.questionIds)
    default:
      return state
  }
}

const prefilledData = (state = {}, action = {}) => {
  switch (action.type) {
    case types.UPDATE_PROFILE_ADDRESS:
      return { ...state, isAddressUpdate: true }
    case types.UPDATE_PROFILE_ADDRESS_DONE:
      return { ...state, isAddressUpdate: false }
    case types.FETCH_SURVEY_SUCCESS: {
      const enterpriseSurveyOnly = omit(action.response.result, 'survey')
      const initialData = action.payload?.options?.initialData

      return (
        {
          ...(initialData || {}),
          ...enterpriseSurveyOnly,
          isAddressUpdate: state.isAddressUpdate
        } || {}
      )
    }
    case types.FETCH_DEFAULT_SURVEY_SUCCESS: {
      const enterpriseSurveyOnly = omit(action.response.result, 'survey')
      const initialData = action.payload?.options?.initialData

      // If it's a default survey it means the user needed to be onboarded,
      // or he needed to update his profile information before continuing.
      // The specs specify that we should keep the existing enterprise survey
      // information (if any) to prefill the enterprise, office, etc.
      return (
        {
          ...state,
          ...(initialData || {}),
          ...enterpriseSurveyOnly,
          isAddressUpdate: state.isAddressUpdate
        } || {}
      )
    }
    default:
      return state
  }
}

const answersByStep = (state = {}, action = {}) => {
  switch (action.type) {
    case types.UPSERT_SURVEY_QUESTION: {
      // Get all the sections on which we potentially need to reset the answer.
      // Those should be the section(s) of the question(s) and subsequent sub-sections
      const sections = Object.keys(state).filter((section) =>
        action.payload.questions.some(
          (question) => Math.round(question.section) === Math.round(+section) && +section >= question.section
        )
      )

      if (!sections || sections.length === 0) {
        return state
      }

      const answersToOmit = action.payload.questions
        .filter((upsertedQuestion) => upsertedQuestion.enabled === false)
        .map((q) => q.referenceKey || q.id)

      // If we disable a section question we want to omit the answer we had for that question
      // and if we had a more specific question in a sub-section we need to remove this answer
      // if it refers to the same question
      return reduce(
        sections,
        (answersBySection, section) => ({
          ...answersBySection,
          [section]: omit(answersBySection[section], answersToOmit)
        }),
        state
      )
    }

    case types.UPDATE_SURVEY_ANSWERS: {
      // Verify if we updated the enterpriseId question (to know if we need to reset the office id)
      // This is for when the office selector is not in the same section than the company selector.
      // Otherwise we are able to just reset the office selector when we update the company selected
      const getEnterpriseIdPreviousAnswer = () =>
        get(state, `[${action.payload.stepNumber}].${REF_KEYS_LIST.accountEnterpriseId}`)
      const getEnterpriseIdUpdatedAnswer = () => action.payload.answers[REF_KEYS_LIST.accountEnterpriseId]
      const enterpriseIdUpdated = Object.keys(action.payload.answers).some(
        (answerKey) =>
          answerKey === REF_KEYS_LIST.accountEnterpriseId &&
          getEnterpriseIdPreviousAnswer() !== getEnterpriseIdUpdatedAnswer()
      )

      // Our normal state update before we apply or not the office id field reset
      const updatedState = {
        ...state,
        [action.payload.stepNumber]: { ...state[action.payload.stepNumber], ...action.payload.answers }
      }

      // If the enterprise id was updated we need to find the section to update and reset the office id
      const officeSection = enterpriseIdUpdated
        ? findKey(updatedState, (answers) => {
            return Object.keys(answers).some((answerKey) => answerKey === REF_KEYS_LIST.accountOfficeId)
          })
        : undefined
      return {
        ...updatedState,
        // If we found an section with the office answer and reset it
        ...(officeSection && {
          [officeSection]: { ...updatedState[officeSection], [REF_KEYS_LIST.accountOfficeId]: null }
        })
      }
    }

    // --- NO DELETE action types are handled here ---
    // We keep all answers even when some questions are removed so we
    // can keep track of the answers. To get only the needed answers considering
    // conditionals we will get only the relevant question answers using a selector (see getAnswers)
    case types.RESET_SURVEY_ANSWERS:
    case types.UPDATE_PROFILE_ADDRESS_DONE:
      return {}
    default:
      return state
  }
}

/**
 * ---------------- SELECTORS ----------------
 */
const emptyObject = {}
const _getQuestionsById = ({ enterpriseSurvey }) => enterpriseSurvey.questionsById
const getIsLoading = ({ enterpriseSurvey }) => enterpriseSurvey.survey.status.isLoading
const _getSurvey = ({ enterpriseSurvey }) => {
  return enterpriseSurvey.survey || {}
}
const makeGetSurvey = (fields) => createSelector([_getSurvey], (survey) => get(survey, fields))
const getSurveyId = createSelector([_getSurvey], (survey) => survey.id)
const getSurveyCustomBranding = ({ enterpriseSurvey }) => enterpriseSurvey.prefilledData.customBranding || emptyObject
const getIsAnonymous = createSelector([_getSurvey], (survey) => survey.isAnonymous)
const getIsDirectOnboarding = createSelector([_getSurvey], (survey) => survey.directOnboarding)
const _getContent = createSelector([_getSurvey], (survey) => survey.content)
const getSuccessHeader = createSelector([_getContent], (surveyContent) => surveyContent.successHeader)
const getSuccessContent = createSelector([_getContent], (surveyContent) => surveyContent.successContent)
const makeGetContent = (fields) => createSelector([_getContent], (content) => get(content, fields))
const getIsLoaded = ({ enterpriseSurvey }) => enterpriseSurvey.survey.status.loaded
const getError = ({ enterpriseSurvey }) => enterpriseSurvey.survey.status.error

const getPrefilledData = ({ enterpriseSurvey }) => enterpriseSurvey.prefilledData
const getIntroName = createSelector(
  [getPrefilledData],
  (prefilledData) => get(prefilledData, 'organization.name') || get(prefilledData, 'enterprise.name')
)
const getSurveyLogo = createSelector(
  [getPrefilledData],
  (prefilledData) => get(prefilledData, 'organization.logo') || get(prefilledData, 'enterprise.logo')
)
const makeGetPrefilledData = (field) => createSelector([getPrefilledData], (prefilledData) => get(prefilledData, field))

const getQuestionsByIdOrRefKey = createSelector([_getQuestionsById], (questionsById) =>
  reduce(
    questionsById,
    (questionsByIdOrRefKey, question) => ({
      ...questionsByIdOrRefKey,
      [_extractQuestionId(question)]: question
    }),
    {}
  )
)

const _getAnswersByStep = ({ enterpriseSurvey }) => enterpriseSurvey.answersByStep

/**
 * 1. Input
 *    _getAnswersByStep -> Answers organized by section number
 *    { [section1]: { [questionId]: { ...Answer } } }
 *
 * 2. Reduce the answers to flatten them out of each section number
 *
 * 3. Output
 *    { [question1]: { ...Answer 1 }, [question2]: { ...Answer 2 } }
 */
const _getAllAnswersById = createSelector([_getAnswersByStep], (answersByStep) => {
  const answers = Object.values(answersByStep)
  return answers.reduce((byId, answer) => merge(byId, answer), {})
})

const makeGetSectionAnswers = (section) =>
  createSelector([_getAnswersByStep], (answersByStep) => answersByStep[section] || {})

/**
 * Get all survey questions considering the conditionals.
 * Ie. We could have a question 2 in the section 2 that should be shown
 *     only when question 1 has a specified value
 *
 * 1. Input multiple selectors to get specific parts of our state:
 *    _getAllAnswersById -> get the answers flatten out of the different
 *                          section numbers (see the selector comment for more details)
 *    questionsById      -> get the questions organized by id
 *    getPrefilledData   -> get the shortlink data to help us validate we have all information
 *                          needed to display or not a more complex component like
 *                          the office autocomplete that depends on the shortlink building and enterprise
 *
 * 2. Process the data:
 *    - separate questions that has conditional mode or is conditional to another question, etc.
 *    - filter out the invalid questions based on the type of component used for that question
 *      (ie. question.type = 'Commute Router' should be able to access a home and office address)
 *    - Sort by order so when we merge or compare questions from a section it is more processing friendly.
 *      (Because we will be able to add them in each section (when we need them by section number)
 *      with Array.push right away and they will already be properly sorted)
 *
 * 3. Output
 *    [{...question, order: 1, section: Y}, {...question, order: 1, section: X}, ...]
 */
export const _getQuestionsWithConditionals = createSelector(
  [_getAllAnswersById, _getQuestionsById, getPrefilledData],
  (allAnswers, questionsById, enterpriseSurveyData) => {
    // Separate questions that has conditionals from questions that doesn't depend on
    // another question answer to check for conditional only on the relevant questions
    const conditionals = []
    const otherQuestions = []
    forEach(questionsById, (question) =>
      question.conditionalQuestionId ||
      question.conditionalMode ||
      question.conditionalCategory ||
      !isNil(question.hideOnProfileUpdate)
        ? conditionals.push(question)
        : otherQuestions.push(question)
    )

    const questions = [
      ...getConditionals(conditionals, allAnswers, questionsById, enterpriseSurveyData),
      ...otherQuestions
    ]
    const validQuestions = filterInvalidQuestions(questions, allAnswers, enterpriseSurveyData)

    return sortByKey(validQuestions, 'order', sortingOrdersEnum.ASC)
  }
)

/**
 * Get all survey questions considering the conditionals.
 * Ie. We could have a question 2 in the section 2 that should be shown
 *     only when question 1 has a specified value
 *
 * 1. Input multiple selectors to get specific parts of our state:
 *    _getQuestionsWithConditionals -> array of ordered questions (by question.order property) considering the
 *                                     conditionals to filter all questions that should not be shown based on previous answers
 *
 * 2. Reduce the array to organize the questions by section number
 *
 * 3. Output
 *    {
 *      [section1]: [{...question, order: 1, section: 1}, {...question, order: 2, section: 1}, ...],
 *      [section2]: [{...question, order: 1, section: 2}, {...question, order: 2, section: 2}, ...],
 *      ...
 *    }
 *
 */
const getQuestionsBySectionWithConditionals = createSelector([_getQuestionsWithConditionals], (questions) =>
  questions.reduce(
    (questionsBySection, question) => ({
      ...questionsBySection,
      [question.section]: questionsBySection[question.section]
        ? questionsBySection[question.section].concat(question)
        : [question]
    }),
    {}
  )
)

/**
 * Gets the ordered sections of the survey considering the conditional questions
 * that are not shown yet. This is important since we might have less sections shown
 * if a section contains ONLY questions conditional to previous answers
 *
 * WARNING: make sure we don't change this behaviour, otherwise we will have empty sections
 * and the app will crash
 *
 * _getQuestionsWithConditionals -> Get all questions that meets the conditional conditions
 */
const getOrderedSections = createSelector([_getQuestionsWithConditionals], (questionsWithConditional) =>
  uniq(questionsWithConditional.map((question) => +question.section)).sort(ascendingCompare)
)

/**
 * Get questions id given in the survey which is the reference key OR the id. If it
 * is the reference key we want to remove the whole question and sub-questions so
 * we take only the first level of data (thus the use of 'split()[0]')
 * @param {Question} question: question entity
 */
const _extractQuestionId = (question) =>
  question.referenceKey
    ? Array.isArray(question.referenceKey)
      ? question.referenceKey[0]
      : question.referenceKey
    : question.id

/**
 * To have the most up to date answers we need to:
 * 1. Deeply merge each section answers with the previous answers.
 * 2. Get only the relevant question answers (we need to consider the conditional
 *    questions that don't need any answers)
 */
const getAnswers = createSelector(
  [_getAnswersByStep, getQuestionsBySectionWithConditionals],
  // that needs to be answered (excluding conditionals that the condition is not respected)
  // Go through all sections and pick only the answers to the questions
  (answersByStep, questionBySectionWithConditional) => {
    const orderedSections = uniq(Object.keys(questionBySectionWithConditional || {})).sort(ascendingCompare)

    return orderedSections.reduce(
      // merge => respect comment case #1
      // pick => respect comment case #2
      (cumulatedAnswers, section) => {
        const sectionQuestions = questionBySectionWithConditional[section]
        return merge(cumulatedAnswers, pick(answersByStep[section], sectionQuestions.map(_extractQuestionId)))
      },
      {}
    )
  }
)

const makeGetAnswer = (questionId, select = (data) => data) =>
  createSelector([getAnswers], (answers) => (answers[questionId] ? select(answers[questionId]) : null))

/**
 * Get all REQUIRED questions (only those that are not optional, are not conditional to another question, etc.)
 * and return the unanswered questions alongside the section in which they belong to allow us to
 * redirect the user to the right section.
 *
 * 1. Input
 *    _getAnswersByStep -> Answers by section number
 *    getQuestionsBySectionWithConditionals -> { [section1]: [...questions of section 1], ... }
 *
 * 2. Process the data
 *    - Reduce on this object ({ [section1]: [...questions of section 1], ... }) with the second param
 *    defined like:
 *    (cumulated array of questions, array of questions for section X, section number X) => cumulated array of questions
 *    - Filter out all optional questions, get all answers ids that are the questions id
 *    - Get the difference between all the question ids of the current  reduce section and the
 *      answers to return only those that are unanswered
 *    - Map to add the section number with the question id
 *
 * 3. Output an array of unanswered question ids associated with the section
 *    [{ id: 'account_address', section: 1 }, ...]
 *
 * OPTIMIZATION possible. Get the ordered sections to be sure we start from the first one and return
 *                        as soon as we find an unanswered question in any section
 */
const getUnansweredQuestions = createSelector(
  [_getAnswersByStep, getQuestionsBySectionWithConditionals],
  (answersByStep, questionBySectionWithConditional) =>
    reduce(
      questionBySectionWithConditional,
      (unansweredQuestions, sectionQuestions, section) => {
        // Get only required question reference key OR question id to find them in the answers and see if it was answered.
        // Statement questions can't be answered so we don't need to consider them even if they were not optional (probably a mistake).
        const questionIds = sectionQuestions
          .filter((question) => !question.optional && question !== QuestionType.statement)
          .map(_extractQuestionId)
        const sectionAnswers = answersByStep[section] || {}
        const answersIds = Object.keys(sectionAnswers).filter((answerId) => {
          const answer = sectionAnswers[answerId]
          return (!isObject(answer) && !isNil(answer)) || Array.isArray(answer) || Object.values(answer || {}).length
        })
        return [...unansweredQuestions, ...difference(questionIds, answersIds).map((qId) => ({ id: qId, section }))]
      },
      []
    )
)

const getPercentageOfCompletion = createSelector(
  [getUnansweredQuestions, _getQuestionsWithConditionals],
  (unanswered, questions) => {
    const requiredQuestionCount = questions.filter((q) => !q.optional).length
    return ((requiredQuestionCount - unanswered.length) / (requiredQuestionCount || Number.POSITIVE_INFINITY)) * 100
  }
)

// New selector to get questionId by referenceKey
export const getQuestionIdForCommuteRouter = createSelector([_getQuestionsById], (questionsById) => {
  // Find the question with the specified referenceKey
  const question = Object.values(questionsById).find((q) => q.referenceKey === REF_KEYS_LIST.commuteProfileRoute)

  // Return the questionId if found, otherwise return null
  return question ? question.id : null
})

export const selectors = {
  getIsLoading,
  makeGetSurvey,
  makeGetContent,
  getIsLoaded,
  getError,
  getIntroName,
  getPrefilledData,
  makeGetPrefilledData,
  getQuestionsBySectionWithConditionals,
  makeGetSectionAnswers,
  getAnswers,
  makeGetAnswer,
  getSurveyId,
  getIsDirectOnboarding,
  getSuccessHeader,
  getSuccessContent,
  getSurveyLogo,
  getOrderedSections,
  getUnansweredQuestions,
  getQuestionsByIdOrRefKey,
  getIsAnonymous,
  getPercentageOfCompletion,
  getSurveyCustomBranding,
  getQuestionIdForCommuteRouter
}

export default combineReducers({ survey, questionsById, prefilledData, answersByStep })
