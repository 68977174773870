import {
  SupportedLocales,
  FrontendLocales,
  extractLocaleInformation,
} from '@commutifi/constants/Locale';
import {
  AU,
  BE,
  CA,
  CL,
  CO,
  CZ,
  DE,
  ES,
  FR,
  GB,
  IT,
  MX,
  NL,
  PH,
  PL,
  PT,
  TH,
  US,
  VI,
} from 'country-flag-icons/react/1x1';
import type { FlagComponent } from 'country-flag-icons/react/1x1';

export { extractLocaleInformation };
export type LocalesType = SupportedLocales | FrontendLocales;

/**
 * Check if the locale is a supported locale. This means it is used for both display and backend purposes (account preferred locale)
 * @param locale - The locale to check
 * @returns True if the locale is a supported locale, false otherwise
 */
export const isSupportedLocaleType = (
  locale: string | undefined
): locale is LocalesType =>
  Boolean(
    locale &&
      SupportedLocales[locale.replace('-', '') as keyof typeof SupportedLocales]
  );

/**
 * Check if the locale is a frontend locale. This means it is used only for display purposes
 * like currency symbol, country flag, etc.
 * @param locale - The locale to check
 * @returns True if the locale is a frontend locale, false otherwise
 */
export const isFrontendLocaleType = (
  locale: string | undefined
): locale is FrontendLocales =>
  Boolean(
    locale &&
      FrontendLocales[locale.replace('-', '') as keyof typeof FrontendLocales]
  );

const FrontendLocaleToSupportedLocaleMap: Record<FrontendLocales, LocalesType> =
  {
    [FrontendLocales.enAU]: SupportedLocales.enAU,
    [FrontendLocales.enPH]: SupportedLocales.enUS,
    [FrontendLocales.enTH]: SupportedLocales.enUS,
    [FrontendLocales.enVI]: SupportedLocales.enUS,
    [FrontendLocales.esCL]: SupportedLocales.esES,
    [FrontendLocales.esCO]: SupportedLocales.esES,
    [FrontendLocales.esMX]: SupportedLocales.esES,
    [FrontendLocales.esUS]: SupportedLocales.esES,
  };
const getFrontendLocaleToSupportedLocale = (
  locale: string | undefined
): LocalesType =>
  isFrontendLocaleType(locale)
    ? FrontendLocaleToSupportedLocaleMap[locale]
    : Locales.enUS;

export const getSupportedLocale = (locale: string | undefined): LocalesType =>
  isSupportedLocaleType(locale)
    ? locale
    : getFrontendLocaleToSupportedLocale(locale);

export const Locales = {
  ...SupportedLocales,
  ...FrontendLocales,
};

export const AVAILABLE_LANGUAGES: {
  id: LocalesType;
  label: string;
  icon: FlagComponent;
}[] = [
  {
    id: SupportedLocales.cs,
    label: 'čeština',
    icon: CZ,
  },
  {
    id: SupportedLocales.deDE,
    label: 'Deutsch (Deutschland)',
    icon: DE,
  },
  {
    id: SupportedLocales.enUS,
    label: 'English (US)',
    icon: US,
  },
  {
    id: SupportedLocales.enCA,
    label: 'English (Canada)',
    icon: CA,
  },
  {
    id: SupportedLocales.enAU,
    label: 'English (Australia)',
    icon: AU,
  },
  {
    id: SupportedLocales.enGB,
    label: 'English (UK)',
    icon: GB,
  },
  {
    id: FrontendLocales.enVI,
    label: 'English (Việt Nam)',
    icon: VI,
  },
  {
    id: FrontendLocales.enTH,
    label: 'English (ประเทศไทย)',
    icon: TH,
  },
  {
    id: FrontendLocales.enPH,
    label: 'English (Pilipinas)',
    icon: PH,
  },
  {
    id: SupportedLocales.esES,
    label: 'Español (España)',
    icon: ES,
  },
  {
    id: FrontendLocales.esMX,
    label: 'Español (México)',
    icon: MX,
  },
  {
    id: FrontendLocales.esUS,
    label: 'Español (Estados Unidos)',
    icon: US,
  },
  {
    id: FrontendLocales.esCL,
    label: 'Español (Chile)',
    icon: CL,
  },
  {
    id: FrontendLocales.esCO,
    label: 'Español (Colombia)',
    icon: CO,
  },

  {
    id: SupportedLocales.frCA,
    label: 'Français (Canada)',
    icon: CA,
  },
  {
    id: SupportedLocales.frFR,
    label: 'Français (France)',
    icon: FR,
  },
  {
    id: SupportedLocales.itIT,
    label: 'Italiano (Italia)',
    icon: IT,
  },
  {
    id: SupportedLocales.nlNL,
    label: 'Nederlands',
    icon: NL,
  },
  {
    id: SupportedLocales.nlBE,
    label: 'Nederlands (België)',
    icon: BE,
  },
  {
    id: SupportedLocales.pl,
    label: 'Polski',
    icon: PL,
  },
  {
    id: SupportedLocales.ptPT,
    label: 'Português (Portugal)',
    icon: PT,
  },
];

export const getLocaleDisplayConfig = (
  locale: LocalesType | `${LocalesType}`
) =>
  AVAILABLE_LANGUAGES.find((l) => l.id === locale) ||
  AVAILABLE_LANGUAGES.find((l) => l.id === Locales.enUS);
