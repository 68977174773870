import { IAxiosRetryConfig } from 'axios-retry'
import { AxiosError } from 'axios'
import { EnterpriseSurveyProps } from '@commutifi/models/EnterprisesSurveys'
import { GetResponse, RequestParams } from '@commutifi-fe/interfaces'
import { SurveyProps } from '@commutifi/models/Surveys'
import { apiCall, dashboardApiServer } from '../../index'
import {
  AccountBody,
  EnterpriseSurveyLinkGenerationBody,
  GetSurveyParams,
  PatchQuestionBody,
  PostQuestionBody,
  PostQuestionResponse,
  SurveyLinkProps,
  SurveyRequestBody
} from './types'
import endpoints from './endpoints'

const apiDashboardServer = apiCall(dashboardApiServer)

export const fetchSurveyLinks = (queryParams: {
  shortlinkId?: string
  surveyId?: string
}): Promise<GetResponse<SurveyLinkProps>> =>
  apiDashboardServer(endpoints.GET.SurveysLinks.route(), {
    method: 'get',
    queryParams
  })

export const fetchDefaultSurvey = (queryParams: { accountId: string }) =>
  apiDashboardServer(endpoints.GET.DefaultSurvey.route(), {
    method: 'get',
    queryParams
  })

export const fetchOneSurvey = (surveyId: string, queryParams: RequestParams) =>
  apiDashboardServer(endpoints.GET.OneSurvey.route(surveyId), {
    method: 'get',
    queryParams
  })

const postSurveyAxiosRetryConfig: IAxiosRetryConfig = {
  retries: 3,
  retryCondition: (error: AxiosError) => error.request.status === 0
}

export const postOnboardSurveyWithoutAccount = (surveyId: string, data: SurveyRequestBody) =>
  apiDashboardServer(endpoints.POST.SurveyAnswers.route(surveyId), {
    method: 'post',
    data,
    'axios-retry': postSurveyAxiosRetryConfig
  })

export const postOnboardSurveyWithAccount = (accountId: string, data: SurveyRequestBody) =>
  apiDashboardServer(endpoints.POST.OnboardSurveyWithAccount.route(accountId), {
    method: 'post',
    data,
    'axios-retry': postSurveyAxiosRetryConfig
  })

export const postFollowUpSurvey = (accountId: string, data: SurveyRequestBody) =>
  apiDashboardServer(endpoints.POST.FollowUpSurvey.route(accountId), {
    method: 'post',
    data,
    'axios-retry': postSurveyAxiosRetryConfig
  })

export const createSurvey = (data: SurveyProps) =>
  apiDashboardServer(endpoints.POST.CreateSurvey.route(), {
    method: 'post',
    data
  })

/**
 * Create or fetch EnterpriseSurvey related to the query params used to narrow down the results
 * of survey invite EnterpriseSurvey
 * @param data - Body parameters see {@link EnterpriseSurveyLinkGenerationBody}
 * @param queryParams - Query parameters
 * @param   generateSurveyLink - Whether or not to generate a survey link
 */
export const createOrFetchEnterpriseSurvey = (
  data: EnterpriseSurveyLinkGenerationBody,
  queryParams?: { generateSurveyLink?: boolean }
): Promise<{
  data: {
    enterpriseSurvey: EnterpriseSurveyProps & { shortlinkId: string }
    surveyLink: string
  }
}> =>
  apiDashboardServer(endpoints.POST.EnterprisesSurveys.route(), {
    method: 'post',
    data,
    queryParams
  })

export const fetchSurveyShortlink = (queryParams: {
  /**
   * Default shortlink id if available (Unique identifier)
   */
  shortlinkId?: string
  /**
   * Number of attempts to fetch the shortlink. If more than 1, the shortlinkId (if provided),
   * might not be used.
   */
  attempts?: number
}): Promise<{ shortlinkId: string; success: boolean; numberOfAttempts: number }> =>
  apiDashboardServer(endpoints.GET.SurveyShortlink.route(), {
    method: 'get',
    queryParams
  })

export const deleteSurveyLink = (enterpriseSurveyId: string): Promise<void> =>
  apiDashboardServer(endpoints.DELETE.SurveyLink.route(enterpriseSurveyId), {
    method: 'delete'
  })

/**
 *
 * @param data - Patch data
 * @param   name - Name to patch anonymous data with
 * @param   email - Email to patch anonymous data with
 * @param   password - Password to patch anonymous data with
 * @returns Promise of the account id to redirect to the dashboard
 */
export const patchAnonymousSurveyAccount = (surveyId: string, data: AccountBody) =>
  apiDashboardServer(endpoints.PATCH.AnonymousSurveyAccount.route(surveyId), {
    method: 'patch',
    data
  })

export const fetchSurveys = (queryParams: GetSurveyParams): Promise<GetResponse<SurveyProps>> =>
  apiDashboardServer(endpoints.GET.Surveys.route(), {
    method: 'GET',
    queryParams
  })

export const fetchEnterpriseSurveys = (
  enterpriseId: string,
  queryParams: GetSurveyParams
): Promise<GetResponse<SurveyProps & { answersCount?: number; questionsCount?: number }>> =>
  apiDashboardServer(endpoints.GET.EnterpriseSurveys.route(enterpriseId), {
    method: 'GET',
    queryParams
  })

export const patchSurvey = (surveyId: string, data: Partial<SurveyProps>) =>
  apiDashboardServer(endpoints.PATCH.PatchSurvey.route(surveyId), {
    method: 'patch',
    data
  })

export const deleteOneQuestion = (surveyId: string, questionId: string) =>
  apiDashboardServer(endpoints.DELETE.DeleteOneQuestion.route(surveyId, questionId), {
    method: 'delete'
  })

export const patchOneQuestion = (surveyId: string, questionId: string, data: PatchQuestionBody) =>
  apiDashboardServer(endpoints.PATCH.PatchOneQuestion.route(surveyId, questionId), {
    method: 'patch',
    data
  })

export const createManyQuestions = (
  surveyId: string,
  data: PostQuestionBody | PostQuestionBody[],
  queryParams?: { isNewPage?: boolean }
): Promise<PostQuestionResponse> =>
  apiDashboardServer(endpoints.POST.CreateManyQuestions.route(surveyId), {
    method: 'post',
    data,
    queryParams
  })

export const moveSurveyPage = (surveyId: string, section: number, body: { toSection: number }) =>
  apiDashboardServer(endpoints.PATCH.MoveSurveyPage.route(surveyId, section), {
    method: 'patch',
    data: body
  })

export const duplicateSurveyPage = (surveyId: string, section: number) =>
  apiDashboardServer(endpoints.POST.DuplicateSurveyPage.route(surveyId, section), {
    method: 'post'
  })

export const deleteSurveyPage = (surveyId: string, section: number) =>
  apiDashboardServer(endpoints.DELETE.SurveyPage.route(surveyId, section), {
    method: 'delete'
  })
